import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { breakpoint } from "../../theme/theme"
import { getSlugFromTitle } from "../../utils/slug"
import { PlainLink } from "../PlainLink/PlainLink"
import { PrimaryButton } from "../PrimaryButton/PrimaryButton"
import { Typography } from "../Typography/Typography"

interface ServiceCardProps {
  index: number
  title: string
  summary: string
  icon: IGatsbyImageData
  landingImage: IGatsbyImageData
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  summary,
  icon,
  landingImage,
  index,
}) => {
  const slug = getSlugFromTitle(title)
  return (
    <Container index={index}>
      <div>
        <GatsbyImage image={landingImage} alt={title} />
      </div>
      <SummaryContainer>
        <IconContainer>
          <GatsbyImage image={icon} alt={title} />
        </IconContainer>
        <Typography variant="h4">{title}</Typography>
        <ReactMarkdown
          components={{
            p: ({ node: _node, ...props }) => (
              <SummaryText variant="p" {...props} />
            ),
          }}
        >
          {summary}
        </ReactMarkdown>
        <div>
          <PlainLink to={`/services/${slug}`}>
            <PrimaryButton>Learn More</PrimaryButton>
          </PlainLink>
        </div>
      </SummaryContainer>
    </Container>
  )
}

const Container = styled("div")<{ index: number }>(({ theme, index }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  color: theme.palette.darkblue.main,
  [breakpoint("md")]: {
    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
    gap: "4em",
  },
}))

const SummaryContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  flex: "1",
})

const IconContainer = styled("div")({
  height: "48px",
  width: "48px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const SummaryText = styled(Typography)({
  lineHeight: "24px",
})
