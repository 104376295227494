import styled from "@emotion/styled"
import { PageProps } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"
import { ContactCard } from "../components/ContactCard/ContactCard"
import { HappyClients } from "../components/HappyClients/HappyClients"
import {
  ClientCaseStudyGraphQLData,
  getHappyClients,
  HappyClientsGraphQLData,
} from "../components/HappyClients/happyClients.utils"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"
import { Paper } from "../components/Paper/Paper"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { PrimaryButton } from "../components/PrimaryButton/PrimaryButton"
import { ServiceCard } from "../components/ServiceCard/ServiceCard"
import { Typography } from "../components/Typography/Typography"
import { breakpoint } from "../theme/theme"
import { getServices } from "./providedServices.util"

export interface ProvidedServicesData extends BasePageData {
  providedServices: {
    frontmatter: {
      title: string
      featuredServices: Array<string>
      summaryCard: {
        title: string
        description: string
        buttonText: string
      }
      servicesIntroduction: {
        title: string
        subtitle: string
      }
    }
  }
  featuredServices: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: {
        title: string
        summary: string
        icon: ImageDataLike
        landingImage: ImageDataLike
      }
    }>
  }
  contactCard: {
    frontmatter: {
      title: string
      subtitle: string
      buttonText: string
      contactEmail: string
    }
  }

  happyClients: HappyClientsGraphQLData
  allCaseStudies: ClientCaseStudyGraphQLData
}

type ProvidedServicesProps = PageProps<ProvidedServicesData>

export const ProvidedServices: React.FC<ProvidedServicesProps> = withLayout(
  { title: "Our Services", variant: "light" },
  (props) => {
    const services = getServices(
      props.data.providedServices.frontmatter.featuredServices,
      props.data.featuredServices.nodes
    )
    const clients = getHappyClients(
      props.data.happyClients.frontmatter.happyClients,
      props.data.allCaseStudies
    )
    const { title, summaryCard, servicesIntroduction } =
      props.data.providedServices.frontmatter

    const happyClients = props.data.happyClients.frontmatter
    const contactCardProps = props.data.contactCard.frontmatter
    return (
      <>
        <StyledPaper color="blue" gradient>
          <MaxWidthContainer>
            <PageTitle variant="h1">{title}</PageTitle>
          </MaxWidthContainer>
        </StyledPaper>
        <MainContainer>
          <SummaryCard color="darkblue">
            <TitleContainer>
              <AccentDivider />
              <SummaryCardTitle variant="h3">
                {summaryCard.title}
              </SummaryCardTitle>
            </TitleContainer>
            <DescriptionContainer>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <DescriptionText variant="p" {...props} />
                  ),
                }}
              >
                {summaryCard.description}
              </ReactMarkdown>
              <PlainLink to="/contact">
                <PrimaryButton>{summaryCard.buttonText}</PrimaryButton>
              </PlainLink>
            </DescriptionContainer>
          </SummaryCard>
          <IntroductionContainer>
            <AccentDivider />
            <Typography variant="h3">{servicesIntroduction.title}</Typography>
            <IntroductionText variant="p">
              {servicesIntroduction.subtitle}
            </IntroductionText>
          </IntroductionContainer>
          <ServicesContainer>
            {services.map((service, index) => (
              <ServiceCard index={index} {...service} key={service.title} />
            ))}
          </ServicesContainer>
          <HappyClients
            title={happyClients.title}
            subtitle={happyClients.subtitle}
            clients={clients}
          />

          <ContactCard {...contactCardProps} />
        </MainContainer>
      </>
    )
  }
)

const StyledPaper = styled(Paper)({
  padding: "8em 2em 15em",
  clipPath: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
  [breakpoint("sm")]: {
    padding: "8em 2em 10em",
  },
})

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "100%",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const MainContainer = styled(MaxWidthContainer)({
  padding: "4em 2em",
  display: "flex",
  flexDirection: "column",
  gap: "6em",
  marginTop: "-270px",
  zIndex: 10,
  position: "relative",
  width: "auto",
  [breakpoint("sm")]: {
    marginTop: "-200px",
  },
})

const SummaryCard = styled(Paper)({
  display: "grid",
  gridTemplateColumns: "1fr",
  padding: "60px 40px",
  gap: "2em",
  borderRadius: "20px",
  [breakpoint("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})

const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const DescriptionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const DescriptionText = styled(Typography)({
  lineHeight: "24px",
})

const IntroductionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3em",
  alignItems: "center",
  color: theme.palette.darkblue.main,
}))

const IntroductionText = styled(Typography)({
  textAlign: "center",
  lineHeight: "24px",
})

const ServicesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
})

const PageTitle = styled(Typography)({
  fontSize: "32px",
  [breakpoint("sm")]: {
    fontSize: "64px",
  },
})

const SummaryCardTitle = styled(Typography)({
  fontSize: "24px",
  [breakpoint("sm")]: {
    fontSize: "32px",
  },
})
