import { graphql } from "gatsby"
import { ProvidedServices } from "../ProvidedServices/ProvidedServices"

export const query = graphql`
  query ProvidedServicesQuery {
    providedServices: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/services.md/" }
    ) {
      frontmatter {
        featuredServices
        title
        summaryCard {
          buttonText
          description
          title
        }
        servicesIntroduction {
          subtitle
          title
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }

    featuredServices: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/netlify/services/" }
        frontmatter: { title: { ne: "Our Services" } }
      }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          summary
          icon {
            childImageSharp {
              gatsbyImageData(height: 40)
            }
          }
          landingImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    happyClients: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/happyClients.md/" }
    ) {
      frontmatter {
        title
        subtitle
        happyClients
      }
    }
    allCaseStudies: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/case_studies/" } }
    ) {
      nodes {
        frontmatter {
          client
          logo {
            childImageSharp {
              gatsbyImageData(height: 40)
            }
          }
        }
      }
    }
    contactCard: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/contactCard.md/" }
    ) {
      frontmatter {
        title
        subtitle
        buttonText
        contactEmail
      }
    }
  }
`
export default ProvidedServices
