import { getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getFilteredFeatures } from "../utils/slug"
import { ProvidedServicesData } from "./ProvidedServices"

export const getServices = (
  services: string[],
  allServices: ProvidedServicesData["featuredServices"]["nodes"]
) => {
  return getFilteredFeatures(services, allServices).map((service) => {
    const { title, summary, icon, landingImage } = service
    const iconImageData = process.env.STORYBOOK
      ? (icon as IGatsbyImageData)
      : getImage(icon)
    const landingImageData = process.env.STORYBOOK
      ? (landingImage as IGatsbyImageData)
      : getImage(landingImage)

    if (!landingImageData) {
      throw new Error(`Could not find image for service: ${service.title}`)
    }
    if (!iconImageData) {
      throw new Error(`Could not fing image for service icon: ${service.title}`)
    }
    return {
      title,
      summary,
      icon: iconImageData,
      landingImage: landingImageData,
    }
  })
}
